<template>
  <page-loading></page-loading>
</template>

<script>
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { onMounted } from "vue";
import PageLoading from "../../components/console/loadings/PageLoading.vue";

export default {
  name: "Bridge",
  components: { PageLoading },
  props: {
    userResourceId: null,
  },
  setup(props) {
    const store = useStore();
    const router = useRouter();

    onMounted(() => {
      postAuthBridge(props.userResourceId);
    });

    const postAuthBridge = (userResourceId) => {
      store
        .dispatch("auth/changeUser", { userResourceId: userResourceId })
        .then(() => {
          router.push({ name: "console.home" });
        });
    };
  },
};
</script>
